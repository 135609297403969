import React from "react"
import styles from "./index.module.css"
import { ChevronLeft, ChevronRight } from "../Icons"
import { Link } from "gatsby"

const PageNavigation = ({ next, previous }) => (
  <nav className={styles.root}>
    {previous && (
      <Link to={previous}>
        <ChevronLeft inline />
        Poprzednia strona
      </Link>
    )}
    {next && (
      <Link to={next}>
        Następna strona
        <ChevronRight inline />
      </Link>
    )}
  </nav>
)

export default PageNavigation;