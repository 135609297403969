import React from "react"
import { graphql } from "gatsby"
import styles from "./index.module.css"

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import Post from "../components/Post"
import SEO from "../components/SEO"
import PageNavigation from "../components/PageNavigation"

class BlogList extends React.PureComponent {
  render() {
    const { data, pageContext } = this.props
    const posts = data.allMarkdownRemark.edges
    const { previousPage } = pageContext
    const { nextPage } = pageContext

    return (
      <Layout location={this.props.location} title="PR0GRAMISTA | Posty">
        <SEO
          title="Strona główna"
        />
        <Bio hiding />
        <div className={styles.postList}>
          {posts.map(({ node }) => (
            <Post
              key={node.fields.slug}
              title={node.frontmatter.title || node.fields.slug}
              slug={node.fields.slug}
              description={node.frontmatter.description || node.excerpt}
              date={node.frontmatter.date}
              image={node.frontmatter.image}
              timeToRead={node.timeToRead}
            />
          ))}
        </div>
        <PageNavigation next={nextPage} previous={previousPage} />
      </Layout>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
